import React from 'react';
import './App.css';
import 'aos/dist/aos.css';
import axios from 'axios'
const baseUrl = 'https://api.motorevents.com.au/api';

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            image: "https://www.motorevents.com.au/wp-content/uploads/2019/12/Rare_Cancer.png",
            text: "Motor Events has teamed up with Rare Cancers Australia.\n" +
                "Together we can make a difference!",
            donated: 0
        }
    }
    onClick = () => {
        window.top.location.href = "https://www.motorevents.com.au/index.php/we-are-on-a-mission/";
    };

    componentDidMount() {
        axios({
            method: 'get',
            url: baseUrl + "/donated_sum"
        }).then(res => {
            this.setState({
                donated: res.data.amount
            });
        }).catch(err => {
            console.log(err)
        })
    }

    render(){
        return(
            <div className="App">
                <div className="root-container">
                    <div className="data-container">
                        <div>
                            <span style={{fontFamily:'Segoe UI',fontWeight:700,fontSize:'30px',color:'#27AE60'}}>$ {this.state.donated} </span><span style={{color:'#27AE60'}}>Donated</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default App;
